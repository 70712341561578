.hide-label {
    label {
        display: none !important;
    }
}

.field-width {
    width: auto !important;
}

.field-required {
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
}

.disable-edit {
    pointer-events: none;
    &.remove-close-icon {
        .icon {
            display: none;
        }
    }
    &.grey-color {
        color: #AAA !important;
        background: #F5F5F5 !important;
    }
}

.hide-form-border {
    border: none !important;
}

.is-view-mode {
    input {
        border: none !important;  
        padding: 0 !important;
        pointer-events: none;
    }
    .dropdown {
        border: none !important;  
        padding: 0 !important;
        pointer-events: none;
        &.clear, .icon {
            display: none !important;
        }
    }
    .react-datepicker__close-icon {
        display: none !important;
    }
    .view-mode-enabled {
        input {
            border: 1px solid rgba(34,36,38,.15) !important;
            padding: 5px !important;
        }
    }
}

.checkbox-with-label {
    display: flex !important;
    label {
        margin-right: 5px;
        cursor: pointer;
    }
}

.align-search-icon {
    position: absolute;
    top: 52%;
    left: -7px;
}

.list-type-none {
    list-style-type: none;
}

.time-only-checkbox {
    display: flex;
    margin-bottom: 15px;

    .checkbox {
        margin-right: 8px;
    }

    label {
        cursor: pointer;
    }
}

.expense-text-area {
    margin-top: 8px;
}

.user-list-wrap {
    .content {
        padding: 0 !important;
    }
}

.black-color {
    color: rgba(0,0,0,.87);
}

.user-chips-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .detail {
        margin-left: 0 !important;
    }
}

.search-form-wrap {
    .field {
        width: 100% !important;
        margin-bottom: 0 !important;
    }
}

.custom-pa-dropdown {
    .item {
        &.disabled {
            pointer-events: none !important;
        }
    }
}