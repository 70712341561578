@import "../../styles/core/core-variable";


.ui.modal {
  background-color: $white-color;

  .content {
    padding: $padding-6;

    &.nopadding {
      padding: $padding-0;
    }
  }
}

.checkbox-alignment {
  display: flex !important;
  margin: 10px 0;
}

.modal-sub-title {
  font-size: 10px;
  line-height: 1.5;
  margin: 3px 0 0 0;
}