@import "./../../styles/core/core-variable";

.colored-bg {
    .header-col th {
        background-color: $background-primary-color !important;
        color: #FFF !important;
    }

    td {
        background-color: $background-light-primary-color !important;
    }

    .table-title th {
        background-color: $background-light-primary-color !important;
    }
}
