@import "./../../styles/core/core-variable";

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #999999;

    .header-top {
        height: 24px;
        width: 100%;
        background-color: #003300;
        color: #ffffff;
        font-size: 13px;
        text-align: right;

        h5 {
            margin: 0px;
            line-height: 24px;
            margin-right: 10px;
            font-size: 12px;
            opacity: 0.8;
        }
    }

    .header-bottom {
        height: 38px;
        width: 100%;
        padding: 4px 10px;
        background-color: $background-primary-color;
        margin: 0px;

        a {
            color: #ffffff;
            line-height: 28px;
            font-weight: 600;
            font-size: $x1lg-font;
        }
    }
}