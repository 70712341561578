@import "./../../styles/core/core-variable";

.breadcrumb {
    padding: 0px;
    list-style: none;
    font-size: $x1lg-font;
    font-weight: 600;
    color: $primary-color;

    li {
        display: inline;
        margin: 0px;
    }

    li:not(:last-child):after {
        display: inline-block;
        content: "-";
        width: 0.8em;
        margin-left: 0.4em;
        color: $primary-color !important;
    }
}