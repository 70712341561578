@import "./core/index";
@import "./App.scss";

html,
body {
    margin: 0;
    padding: 0;
    font-family: $primary-font;
    background: $background-color;
    height: 100%;
}

.page-container {
    position: relative;
    min-height: 100vh;
}

.page-content {
    padding: 60px 0px 120px;
}

.form label.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $danger-color;
}

.ui.primary.button {
    background-color: $primary-color;
}

.ui.primary.button:hover,
.ui.primary.button:active,
.ui.primary.button:focus {
    background-color: $primary-color-hover;
}

.horizontal-list {
    margin: 0px;
    padding: 0px;
    list-style: none;

    li {
        display: inline-block;
        padding: 0px 6px;
    }
}

.ui.dropdown .menu>.item {
    font-size: $sm-font;
}

.ui.selection.dropdown {
    font-size: $sm-font;
}

.kpi-measure-fields {
    display: flex;
    align-items: center;
    gap: 5px;

    .field {
        margin-bottom: 0 !important;
    }
}
