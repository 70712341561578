@import "./../../styles/core/core-variable";

.progress-bar {
    height: 4px;
    background-color: $secondary-color;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: $background-primary-color;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

.loading-container {
    display: flex;
    align-items: center;

    &.inverted {
        background-color: #e2e2e266;
    }
    &.global {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        background-color: rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
    }
}
