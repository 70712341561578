@import "./core-variable";

button.standard {
    background: #ffffff !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
}

button.standard:hover {
    background: $primary-light-color !important;
}